<template>
  <div class="cusListAdd-container">
    <!-- <el-drawer
      class="cusListAdd-drawer"
      title="新增店铺"
      :visible.sync="drawer"
      :append-to-body="true"
      direction="rtl"
      :before-close="handleClose"
    > -->
     <el-dialog v-dialogDrag class="cusListAdd-drawer" title="新增店铺" :append-to-body="true" :visible.sync="drawerShop" width="530px" :before-close="handleClose" :close-on-click-modal="false">
      <div class="drawer-container">
        <el-form :model="ruleFormAdd" :rules="rulesAdd" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="所属客户：">
            {{ruleFormAdd.customerName}}
          </el-form-item>
          <el-form-item label="店铺logo" prop="oldPassword">
            <div v-if="ruleFormAdd.shopLogo" class="logo">
              <img class="img" :src="ruleFormAdd.shopLogo" alt="" />
            </div>
            <div class="upload-icon-container">
              <el-button class="common-screen-btn">选择图片</el-button>
              <input type="file" class="upload-icon-input" @input="chooseFile" />
            </div>
          </el-form-item>
          <el-form-item label="店铺名称：" prop="shopName">
            <el-input
              class="common-screen-input_100"
              placeholder="请输入店铺名称"
              v-model="ruleFormAdd.shopName"
            ></el-input>
          </el-form-item>
          <!-- <el-form-item label="平台/类目：" prop="platId">
            <el-cascader
              :options="platList"
              class="common-screen-input_100"
              placeholder="请选择平台或类目"
              :props="{
                label: 'platformName',
                value: 'id'
              }"
              clearable
              v-model="ruleFormAdd.platId"
            ></el-cascader>
          </el-form-item> -->
          <el-form-item label="所属平台：" prop="platformId">
            <el-select class="common-screen-input_100" v-model="ruleFormAdd.platformId" placeholder="所属平台" clearable filterable >
              <el-option v-for="item in platList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属类目：">
            <el-select class="common-screen-input_100" v-model="ruleFormAdd.categoryId" placeholder="所属类目" clearable filterable remote :remote-method="(val)=>{remoteCategory(val)}" @focus="selectClear">
                    <el-option v-for="item in categoryList" :key="item.id" :label="item.platformName" :value="item.id" > </el-option>
                    <div class="common-select-page">
                        <div v-if="categoryParams.pageNum != 1" @click="() => {selectPage('up')}">上一页</div>
                        <div v-if="categoryParams.pageNum == 1" style="color: #999">上一页</div>
                        <div v-if="categoryList && categoryList.length == 10" @click="() => {selectPage('down')}">下一页</div>
                        <div v-if="categoryList && categoryList.length < 10" style="color: #999">下一页</div>
                    </div>
                </el-select>
          </el-form-item>
          <el-form-item label="店铺金额：" prop="price">
            <el-input class="common-screen-input_100" type="number" placeholder="请输入店铺金额" v-model="ruleFormAdd.price"></el-input>
          </el-form-item>
           <el-form-item label="协议子账号数" prop="agreeSubNum">
            <el-input type="number" @mousewheel.native.prevent class="dialog-input" v-model="ruleFormAdd.agreeSubNum" placeholder="协议子账号数" maxLength="50"></el-input>
          </el-form-item>
          <el-form-item label="协议日均咨询量" prop="conslutDayNum">
            <el-input type="number" @mousewheel.native.prevent class="dialog-input" v-model="ruleFormAdd.conslutDayNum" placeholder="协议单账号日均咨询量" maxLength="50"></el-input>
          </el-form-item>
          <el-form-item label="联系人：">
            <el-input
              class="common-screen-input_100"
              placeholder="请输入联系人"
              v-model="ruleFormAdd.contactName"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系方式：">
            <el-input
              class="common-screen-input_100"
              placeholder="请输入联系方式"
              v-model="ruleFormAdd.contactPhone"
            ></el-input>
          </el-form-item>
          <el-form-item label="运营负责：">
            <el-select
              class="common-screen-input_100"
              :key="updateCascader"
              v-model="ruleFormAdd.busineseUserIds"
              placeholder="请选择商务负责人"
              filterable
              multiple
            >
              <el-option
                v-for="item in userList"
                :key="item.id + ''"
                :label="item.nickName"
                :value="item.id + ''"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="服务时间：" prop="datePicker">
            <el-date-picker
              class="common-screen-input_100"
              v-model="ruleFormAdd.datePicker"
              type="daterange"
              range-separator="~"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="预警时间：">
            <el-radio-group v-model="ruleFormAdd.beforeDay">
              <el-radio-button label="1">提前3天</el-radio-button>
              <el-radio-button label="2">提前7天</el-radio-button>
              <el-radio-button label="3">提前15天</el-radio-button>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="当日发送时间：">
            <el-time-picker
              class="time"
              v-model="ruleFormAdd.warnTime"
              format="HH:mm"
              value-format="HH:mm"
              placeholder="请选择时间"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="是否考核均值：">
            <el-radio-group @change="(val) => { radioChange(val) }" v-model="ruleFormAdd.isAssessment">
              <el-radio :label="1">是</el-radio>
              <el-radio :label="0">否</el-radio>
            </el-radio-group>
            <ul v-show="ruleFormAdd.isAssessment" class="ulline">
              <li><span class="lione">考核项目</span><span class="litwo licolor">月均值设置</span></li>
              <li><span class="lione">首响</span><span class="litwo"><el-input v-model="ruleFormAdd.firstTime" class="ulipt" type="number" placeholder="请输入"></el-input>s</span></li>
              <li><span class="lione">平响</span><span class="litwo"><el-input v-model="ruleFormAdd.avgTime" class="ulipt" type="number" placeholder="请输入"></el-input>s</span></li>
              <li><span class="lione">问答比</span><span class="litwo"><el-input v-model="ruleFormAdd.questionAnswerRatio" class="ulipt" type="number" placeholder="请输入"></el-input>%</span></li>
              <li><span class="lione">三分钟回复率</span><span class="litwo"><el-input v-model="ruleFormAdd.thereTimeRatio" class="ulipt" type="number" placeholder="请输入"></el-input>%</span></li>
              <li><span class="lione">五分钟回复率</span><span class="litwo"><el-input v-model="ruleFormAdd.fiveTimeRatio" class="ulipt" type="number" placeholder="请输入"></el-input>%</span></li>
            </ul>
          </el-form-item>
          <el-form-item label="交接文档：" prop="fileUrl">
            <el-input
              style="display: none"
              v-model="ruleFormAdd.fileUrl"
            ></el-input>
            <div class="common-upload-container" style="margin: 0">
              <el-button class="common-screen-btn" type="primary"
                >上传文档</el-button
              >
              <input
                class="common-upload-input"
                @input="
                  (e) => {
                    uploadTradeXslx(e);
                  }
                "
                type="file"
              />
            </div>
            <el-button
              class="common-screen-btn"
              type="text"
              @click="() => downloadTemplate()"
              >模板下载</el-button
            >
            <div v-if="ruleFormAdd.fileUrl" class="upload">
              已上传
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button class="common-screen-btn" @click="handleClose()" >取 消</el-button>
        <el-button class="common-screen-btn" :loading="btnLoading" type="primary" @click="() => submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { addEditShop,defaultData } from "../../../service/customer.js";
import { DateTransform, Config } from "../../../utils/index.js";
import { upload } from "../../../service/upload.js";
import { selectCategory, selectPlatform } from "../../../service/common.js"
export default {
  props: ["drawerShop", "finishedList", "userList", "btnLoading", "residuePrice"],
  data() {
    let rulePrice = (rule, value, callback) => {
      if (value) {
        value = value.replace(/[\u202D+\u202C+\s+]/g, "");
        if (Number(value) > this.residuePrice) {
          callback(new Error(`订单剩余金额${this.residuePrice}`));
        } else if (Number(value) < 0) {
          callback(new Error(`订单金额不能小于0`));
        } else {
          callback()
        }
      } else {
        callback();
      }
    };
    let rulePhone = (rule, value, callback) => {
      let reg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (value) {
        value = value.replace(/[\u202D+\u202C+\s+]/g, "");
        if (!reg.test(value)) {
          callback(new Error("手机号格式不正确!"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      updateCascader: 1, // 用于部门下拉key值，重新渲染，防止警告
      ruleFormAdd: { // form提交数据
        customerName: '',
        shopLogo: '',
        shopName: "",
        platformId: '',
        categoryId: '',
        price: '',
        agreeSubNum: '',
        conslutDayNum: '',
        contactName: '',
        contactPhone: '',
        datePicker: [],
        busineseUserIds: [],
        beforeDay: '',
        warnTime: '',
        isAssessment: 1,
        firstTime: "",
        avgTime: "",
        questionAnswerRatio: "",
        thereTimeRatio: "",
        fiveTimeRatio: "",
      },
      platId: [],
      rulesAdd: { // 校验规则
        shopName: [{ required: true, message: "店铺名称不能为空", trigger: "change" }],
        platformId: [{required: true,message: "所属平台不能为空", trigger: "change"}],
        categoryId: [{required: true,message: "所属平台/类目不能为空", trigger: "change"}],
        datePicker: [{ required: true, message: "服务时间不能为空", trigger: "change" }],
        price: [{required: true,message: "店铺金额不能为空", trigger: "change"},{ validator: rulePrice, trigger: "change" }],
        contactPhone: [{ validator: rulePhone, trigger: "change" }],
        fileUrl: [{ required: true, message: "请上传交接文档", trigger: "change" }]
      },
      fileNameText: "",
      tableRow: "",
      roleSelect: "",
      selectDepart: [],
      pickerOptions: {
        // 时间禁用时间范围
        disabledDate: (time) => {
          return time.getTime() < new Date().getTime();
        },
      },
      platList: [], // 所属平台
      categoryList: [], //类目下拉
      categoryParams: {
          pageNum: 1,
          pageSize: 10,
          name: ''
      },
      /////////服务均值////////////////////
      checkRadio: 1, //是否考核均值
      defaVal: {},
      deData: {}
    };
  },
  created() {
    this.selectPlatform();
    this.selectCategory();
    this.getDefaultData()
  },
  methods: {
    selectClear () { // 下拉清空时触发
        this.categoryParams.pageNum = 1
        this.categoryParams.name = ''
        this.selectCategory()
    },
    selectPage(type) { // 下拉分页
        if (type == 'up') {
            this.categoryParams.pageNum--
        } else {
            this.categoryParams.pageNum++
        }
        this.selectCategory()
    },
    remoteCategory(val) { // 所属类目下拉-筛选
        this.categoryParams.pageNum = 1
        this.categoryParams.name = val
        this.selectCategory()
    },
    async selectCategory() { // 类目下拉
        let categoryParams = {...this.categoryParams}
        let { data } = await selectCategory(categoryParams)
        this.categoryList = data.list
    },
    async selectPlatform() { // 平台下拉
        let { data } = await selectPlatform({pageNum: -1})
        this.platList = data
    },
    async uploadTradeXslx(e) {
      // 上传交接文档
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (file.size / 1024 > 1024 * 50) {
        e.target.value = "";
        return this.$message.error('文件大小不能超过50M')
      }
      if (!Config.fileType.PWEIType.includes(format)) {
        e.target.value = "";
        return this.$message.error(`只能上传 ${Config.fileMessage.PWEIType} 格式的文件`)
      }
      // if (format != "xls" && format != "xlsx") {
      //   this.$message.error("仅支持xls、xlsx格式文件");
      //   e.target.value = "";
      //   return;
      // }
      let ruleFormAdd = { ...this.ruleFormAdd };
      let resData = (await upload({ file: file })).data;
      e.target.value = "";
      this.ruleFormAdd = Object.assign(ruleFormAdd, {
        fileUrl: resData.filename,
        fileId: resData.id
      });
      this.fileNameText = file.name;
    },
    downloadTemplate() {
      // 下载模板-暂时写死
      window.location.href =
        "https://sy-my.oss-cn-zhangjiakou.aliyuncs.com/sy/file/%E4%B8%89%E5%9C%86%E5%BA%97%E9%93%BA%E4%BA%A4%E6%8E%A5%E8%A1%A8.xlsx";
    },
    async chooseFile(e) {
      let file = e.target.files[0];
      let format = file.name.split(".")[file.name.split(".").length - 1];
      if (
        format != "bmp" &&
        format != "jpg" &&
        format != "png" &&
        format != "gif" &&
        format != "jpeg"
      ) {
        this.$message.error("仅支持bmp、png、jpg、jpeg、gif格式文件");
        e.target.value = "";
        return;
      }
      let ruleFormAdd = { ...this.ruleFormAdd };
      let resData = (await upload({ file: file })).data;
      e.target.value = "";
      this.ruleFormAdd = Object.assign(ruleFormAdd, {
        shopLogo: resData.filename
      });
    },
    submitForm(formName) {
      // 新增修改提交
      let ruleFormAdd = { ...this.ruleFormAdd };
      this.$vuex.commit("btnLoading", true);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // if (ruleFormAdd.platId && ruleFormAdd.platId.length) {
          //   // 处理所属平台/类目数据
          //   ruleFormAdd.platformId = ruleFormAdd.platId[0];
          //   if (ruleFormAdd.platId[1]) {
          //     ruleFormAdd.categoryId = ruleFormAdd.platId[1];
          //   } else {
          //     ruleFormAdd.categoryId = "";
          //     // return this.$message.error("类目不能为空")
          //   }
          // }
          if (ruleFormAdd.datePicker && ruleFormAdd.datePicker.length) {
            // 处理时间数据
            ruleFormAdd.startTime = DateTransform(ruleFormAdd.datePicker[0]);
            if (ruleFormAdd.datePicker[1]) {
              ruleFormAdd.endTime = DateTransform(ruleFormAdd.datePicker[1]);
            }
            delete ruleFormAdd.datePicker;
          }
          if (ruleFormAdd.busineseUserIds) {
            ruleFormAdd.busineseUserIds = ruleFormAdd.busineseUserIds.join(",");
          }
          ruleFormAdd.orderId = this.tableRow.id;
          // ruleFormAdd.isAssessment = 0
          let res = await addEditShop(ruleFormAdd);
          if (res.code == 200) {
            this.$message.success("添加成功");
            this.$emit('updateShop')
            this.handleClose();
          }
          this.$vuex.commit("btnLoading", false);
        } else {
          this.$vuex.commit("btnLoading", false);
          return false;
        }
      });
    },
    getDataInfo(selectDepart, orderInfo) { // 被父组件调用传值
      if (selectDepart) {
        this.selectDepart = selectDepart ? selectDepart : [];
        this.updateCascader++;
      }
      if (orderInfo) {
        this.tableRow = orderInfo;
        // this.ruleFormAdd = {
        //   customerName: orderInfo.customerName || orderInfo.customer_name,
        //   customerId: orderInfo.customer_id || orderInfo.customerId,
        //   companyId: 0,
        //   datePicker: [new Date(orderInfo.startTime), new Date(orderInfo.endTime)]
        // }
        this.ruleFormAdd.customerName = orderInfo.customerName || orderInfo.customer_name,
        this.ruleFormAdd.customerId = orderInfo.customer_id || orderInfo.customerId,
        this.ruleFormAdd.companyId = 0,
        this.ruleFormAdd.datePicker = [new Date(orderInfo.startTime), new Date(orderInfo.endTime)]
      }
      this.pickerOptions = {
        // 时间禁用时间范围
        disabledDate: (time) => {
          return time.getTime() < new Date(orderInfo.startTime).getTime() || time.getTime() > new Date(orderInfo.endTime).getTime()
        },
      }
      if (this.$refs["ruleForm"]) {
        this.$refs["ruleForm"].resetFields();
      }
    },
    handleClose(done) {
      this.$emit("shopAddClose", done);
      this.ruleFormAdd.isAssessment = 1
      this.ruleFormAdd.firstTime = this.deData.firstTime
      this.ruleFormAdd.avgTime = this.deData.avgTime
      this.ruleFormAdd.questionAnswerRatio = this.deData.questionAnswerRatio
      this.ruleFormAdd.thereTimeRatio = this.deData.thereTimeRatio
      this.ruleFormAdd.fiveTimeRatio = this.deData.fiveTimeRatio
    },
    radioChange(val){
      if(val){
        this.ruleFormAdd.firstTime = this.deData.firstTime
        this.ruleFormAdd.avgTime = this.deData.avgTime
        this.ruleFormAdd.questionAnswerRatio = this.deData.questionAnswerRatio
        this.ruleFormAdd.thereTimeRatio = this.deData.thereTimeRatio
        this.ruleFormAdd.fiveTimeRatio = this.deData.fiveTimeRatio
      }
    },
    async getDefaultData(){
      const { data } = await defaultData()
      this.deData = data
      this.ruleFormAdd.firstTime = data.firstTime
      this.ruleFormAdd.avgTime = data.avgTime
      this.ruleFormAdd.questionAnswerRatio = data.questionAnswerRatio
      this.ruleFormAdd.thereTimeRatio = data.thereTimeRatio
      this.ruleFormAdd.fiveTimeRatio = data.fiveTimeRatio
    }
  }
};
</script>
<style lang="less" scoped>
.cusListAdd-drawer {
  .upload-icon-input {
    width: 88px;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }
  .drawer-container {
    padding: 0 44px 0 24px;
    text-align: left;
    .demo-ruleForm {
      .logo {
        margin-right: 16px;
        display: inline-block;
        .img {
          height: 40px;
        }
      }
      .time {
        width: 175px !important;
      }
      .ulline{
        padding-left: 32px;
        border-left: 3px solid #1890ff;
        .lione{
          display: inline-block;
          width: 150px;
          color: #101010;
        }
        .litwo{
          color: #989998;
          font-size: 14px;
        }
        .licolor{
            color: #101010;
        }
        .ulipt{
          width: 92px;
        }
      }
    }
  }
  .upload-icon-container {
    display: inline-block;
    position: relative;
    width: 88px;
    height: 40px;
    .common-screen-btn {
      margin: 0;
    }
  }
  // /deep/ .el-drawer__open .el-drawer.rtl {
  //   width: 38% !important;
  // }
  /deep/ .el-radio-button__inner {
    padding: 8px 6px;
    font-size: 12px;
  }
  .upload {
    margin-left: 16px;
    color: #87d068;
  }
}
</style>
